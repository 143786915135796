import React from "react"
import ReactMarkdown from "react-markdown"
import * as styles from "./styles/MainPich.module.css"

import { Link } from "gatsby"

export const MainPichComponent = ({ mainpitch_home }) => {
  return (
    <section className={styles.wrapper}>
      <div className="py-3">
        <div className="container">
          <div className="row text-center">
            <div className="col-12 col-lg-4 mx-auto py-3">
              <ReactMarkdown
                className={styles.heading}
                children={mainpitch_home.heading}
              />
              <div className="pt-3">
                <Link className={styles.link} to={mainpitch_home.link}>
                  {mainpitch_home.description}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainPichComponent
