import React from "react"
import ReactMarkdown from "react-markdown"

export const InterestedComponent = ({ interested_home }) => {
  return (
    <section>
      <div className="bg-gray-dark text-white py-3">
        <div className="container">
          <div className="row text-center">
            <div className="col-12 col-lg-4 mx-auto py-3">
              <h4>
                <ReactMarkdown children={interested_home.heading} />
              </h4>
              <ReactMarkdown
                children={interested_home.description}
                className="text-muted"
              />
              <div>
                <button
                  className="text-decoration-none btn btn-light"
                  aria-current="page"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-popup"
                >
                  Request for Demo <i className="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InterestedComponent
