import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Grid, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/grid"
import "swiper/css/pagination"
import "./styles/offerings.scss"

const ServicesList = ({ services_home }) => {
  const data = useStaticQuery(graphql`
    query ServicesAllQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___sequence] }
        filter: { frontmatter: { templateKey: { eq: "services-slug" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              templateKey
              name
              description
              url
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    height: 200
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  const servicesArr = data.allMarkdownRemark.edges

  return (
    <section className="py-3 py-lg-5 ServicesList">
      <div className="container">
        <h2 className="section_heading text-center pb-3">
          {services_home.heading}
        </h2>
        <div className="row justify-content-md-center">
          <Swiper
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                grid: {
                  rows: 3,
                },
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            modules={[Grid, Pagination]}
            className={`customSwiper ${
              servicesArr.length === 3 ? "center" : ""
            }`}
          >
            {servicesArr.map(({ node }, index) => (
              <SwiperSlide key={`Services_${index}`}>
                <ItemTemplate item={node.frontmatter} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
export const ItemTemplate = ({ item }) => {
  return (
    <Link to={item.url} className="servicesCard h-100 mx-3 pb-2">
      <div className="card h-100">
        <div className="card-img">
          <GatsbyImage
            image={item.image.childImageSharp.gatsbyImageData}
            alt={item.name}
            className="card-img-top"
          />
        </div>
        <div className="card-header border-0 bg-transparent d-flex align-items-center">
          <h6 className="card-title mb-0">{item.name}</h6>
        </div>
        <div className="card-body d-none d-lg-block py-0">
          <ReactMarkdown children={item.description}></ReactMarkdown>
        </div>
      </div>
    </Link>
  )
}
ItemTemplate.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

export default ServicesList
