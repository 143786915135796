import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import "swiper/css"
import "swiper/css/navigation"

const ClientList = () => {
  const data = useStaticQuery(graphql`
    query ClientsQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "clients" } }) {
        id
        frontmatter {
          templateKey
          name
          blurb {
            name
            url
            image {
              childImageSharp {
                gatsbyImageData(height: 30, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  `)
  const { markdownRemark: markup } = data
  const { name, blurb } = markup.frontmatter
  return (
    <section className="py-3 py-lg-5 bg-light ClientsList">
      <div className="container">
        {name && <h2 className="section_heading text-center pb-3">{name} </h2>}
        <div>
          <Swiper
            slidesPerView={1}
            navigation={true}
            breakpoints={{
              0: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 6,
              },
            }}
            modules={[Navigation]}
            className={`customSwiper ${blurb.length === 3 ? "center" : ""}`}
          >
            {blurb.map((item, index) => (
              <SwiperSlide key={`clients_${index}`}>
                <ItemTemplate item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
export const ItemTemplate = ({ item }) => {
  return (
    <div className="clientsCard h-100 mx-2">
      <div className="card h-100 text-center justify-content-center border-0">
        <div className="card-img p-2 p-lg-3">
          <GatsbyImage
            image={item.image.childImageSharp.gatsbyImageData}
            alt={item.name}
          />
        </div>
        {/* <div className="card-header border-0 bg-transparent d-none d-lg-block">
          <a
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary text-capitalize text-bold text-decoration-none"
          >
            {item.name}
          </a>
        </div> */}
      </div>
    </div>
  )
}
ItemTemplate.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

export default ClientList
