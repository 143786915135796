import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import "./styles/resources.scss"

const ResourcesList = ({ resources_home }) => {
  const data = useStaticQuery(graphql`
    query ResourcesListQuery {
      allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___sequence] }
        filter: { frontmatter: { homeSection: { eq: "resources" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              templateKey
              homeSection
              name
              description
              url
              image {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `)
  const Resources = data.allMarkdownRemark.edges

  return (
    <section className="py-3 py-lg-5 ResourcesList">
      <div className="container-fluid">
        <h2 className="section_heading text-center pb-3">
          {resources_home.heading}{" "}
        </h2>
        <div className="row g-0">
          {Resources.map(({ node }, index) => (
            <ItemTemplate
              key={`Resources_${index}`}
              item={node.frontmatter}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
export const ItemTemplate = ({ item, index }) => {
  return (
    <Link to={item.url} className="col-3 mb-3 resourcesCard">
      <div
        className={`card h-100 border-0 ${index % 2 === 0 ? "even" : "odd"}`}
      >
        <div className="card-img animate__animated animate__pulse">
          <GatsbyImage
            image={item.image.childImageSharp.gatsbyImageData}
            alt={item.name}
          />
        </div>

        <div className="card-body px-0">
          <div className="card-content">
            <h4 className="card-title">{item.name}</h4>
            <div className="text-muted d-none d-lg-block">
              <ReactMarkdown children={item.description}></ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
ItemTemplate.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

export default ResourcesList
