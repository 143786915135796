import React from "react"
import ReactMarkdown from "react-markdown"

import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const JoinTeamComponent = ({ jointeam_home }) => {
  const { blurb, image, heading, description } = jointeam_home
  return (
    <section className="container pt-5">
      <div className="row align-items-center">
        <div className="col-lg-6 mb-5">
          <ReactMarkdown children={heading}></ReactMarkdown>
          <ReactMarkdown children={description}></ReactMarkdown>

          <ul className="list-group">
            {blurb.length &&
              blurb.map((item, index) => (
                <li
                  className="list-group-item mb-3 p-0 rounded-pill border"
                  key={`jointeam_${index}`}
                >
                  <ReactMarkdown
                    components={{ a: CustomLink, p: React.Fragment }}
                    children={item.link}
                  ></ReactMarkdown>
                </li>
              ))}
          </ul>
        </div>
        <div className="col-lg-6 text-center text-lg-end mb-5">
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={heading}
          />
        </div>
      </div>
    </section>
  )
}

const CustomLink = ({ to, href, children, ...otherProps }) => {
  return (
    <Link
      to={href}
      {...otherProps}
      className="text-decoration-none text-black d-flex justify-content-between fs-6 p-3"
    >
      <span>{children}</span>
      <i className="bi bi-arrow-right-circle-fill"></i>
    </Link>
  )
}

export default JoinTeamComponent
