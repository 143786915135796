import React from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import Banner from "../components/Banner"

const ListContainer = props => (
  <>
    {typeof window !== "undefined" &&
    window.location.toString().includes("testimonials") ? (
      <div {...props} />
    ) : (
      <Swiper
        pagination={{
          clickable: true,
        }}
        centeredSlides={true}
        loop={true}
        spaceBetween={5}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 2,
          },
        }}
        modules={[Pagination]}
        className={`customSwiper customAnimation ${
          props.blurb.length === 3 ? "center" : ""
        }`}
        {...props}
      />
    )}
  </>
)

const TestimonialsComponent = () => {

  const data = useStaticQuery(graphql`
    query TestimonialsPageQuery2 {
      markdownRemark(frontmatter: { templateKey: { eq: "testimonials" } }) {
        id
        html
        fields {
          slug
        }
        frontmatter {
          heading
          description
          banner {
            heading
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
          blurb {
            title
            image {
              childImageSharp {
                gatsbyImageData(height: 100, quality: 100, layout: CONSTRAINED)
              }
            }
            authorname
            authortitle
            authorbio
          }
        }
      }
    }
  `)
  const { markdownRemark: markup } = data
  const { blurb, banner } = markup.frontmatter
  return (
    <section
      className="testimonialsPage"
      style={{ background: "url('./img/section_bg.jpg') no-repeat 100%/cover" }}
    >
      {typeof window !== "undefined" &&
        window.location.toString().includes("testimonials") && (
          <Banner data={banner} />
        )}

      <div
        className={
          typeof window !== "undefined" &&
          window.location.toString().includes("testimonials")
            ? "container"
            : ""
        }
      >
        <div className="py-3 py-lg-5">
          {/* <h2 className="section_heading text-center pb-3 pb-lg-4">{heading}</h2> */}
          <ListContainer blurb={blurb}>
            {blurb.length &&
              blurb.map((item, index) => (
                <SwiperSlide key={`Testimonials_${index}`}>
                  <ItemTemplate item={item} />
                </SwiperSlide>
              ))}
          </ListContainer>
        </div>
      </div>
    </section>
  )
}
export const ItemTemplate = ({ item }) => {
  return (
    <div
      to={item.url}
      className="row g-0 bg-white rounded shadow border-0 h-100 mx-3 mx-lg-0 mb-4"
    >
      <div className="col-12 col-lg-4">
        <div className="h-100 d-flex align-items-center justify-content-center bg-light rounded-start">
          <GatsbyImage
            image={item.image.childImageSharp.gatsbyImageData}
            alt={item.title}
            className="img-fluid"
          />
        </div>
      </div>
      <div className="col-12 col-lg-8">
        <div className="p-3 d-flex flex-column">
          <div>
            <i className="bi bi-quote fs-1 text-primary"></i>
            <ReactMarkdown
              className="card-text"
              children={item.authorbio}
            ></ReactMarkdown>
          </div>

          <div className="mt-auto">
            <h6 className="card-title mb-0 text-primary">{item.authorname}</h6>
            <p className="card-text">
              <small className="text-muted"> {item.authortitle}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
ItemTemplate.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    authorname: PropTypes.string,
    authortitle: PropTypes.string,
    authorbio: PropTypes.string,
  }),
}
export default TestimonialsComponent
