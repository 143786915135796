import React from "react"
import ReactMarkdown from "react-markdown"
import * as styles from "./styles/Banner.module.css"
import { Swiper, SwiperSlide } from "swiper/react"
import { Keyboard, Navigation, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { GatsbyImage } from "gatsby-plugin-image"

export const BannerComponent = ({ banner_blurb }) => {
  return (
    <div>
      <Swiper
        slidesPerView="1"
        pagination={{
          clickable: true,
          bulletClass: `swiper-pagination-bullet bg-white`,
        }}
        keyboard={{
          enabled: true,
        }}
        breakpoints={{
          0: {},
          768: {},
          1024: {
            navigation: true,
          },
        }}
        modules={[Keyboard, Navigation, Pagination]}
        className={`customSwiper homeBanner`}
      >
        {banner_blurb.length &&
          banner_blurb.map((item, index) => (
            <SwiperSlide key={`home_banner_${index}`}>
              <ItemTemplate item={item} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  )
}

export const ItemTemplate = ({ item }) => {
  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.bannerImage}>
        <GatsbyImage
          image={item.image.childImageSharp.gatsbyImageData}
          alt={item.heading}
          style={{
            opacity: 0.8,
          }}
        />
      </div>
      <div className={styles.bannerContent}>
        <div className="container">
          <div className="row align-items-center no-gutters">
            <div className="col-12 col-lg-6">
              <ReactMarkdown
                children={item.heading}
                className={styles.heading}
              ></ReactMarkdown>
              <ReactMarkdown
                className="d-none d-lg-block"
                children={item.description}
              ></ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BannerComponent
