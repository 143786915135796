import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import { Grid, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/grid"
import "swiper/css/pagination"
import "./styles/offerings.scss"

const ProductsList = ({ products_home }) => {
  const data = useStaticQuery(graphql`
    query ProductsAllQuery {
      allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___sequence] }
        filter: { frontmatter: { templateKey: { eq: "products-slug" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              templateKey
              name
              description
              url
              image {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
              logo {
                childImageSharp {
                  gatsbyImageData(height:45,  quality: 100, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `)
  const productsArr = data.allMarkdownRemark.edges

  return (
    <section className="py-3 py-lg-5 ProductsList">
      <div className="container">
        {products_home?.heading && (
          <h2 className="section_heading text-center pb-3">
            {products_home.heading}{" "}
          </h2>
        )}
        <div>
          <Swiper
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 2,
                grid: {
                  rows: 2,
                },
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            modules={[Grid, Pagination]}
            className={`customSwiper ${
              productsArr.length === 3 ? "center" : ""
            }`}
          >
            {productsArr.map(({ node }, index) => (
              <SwiperSlide key={`Products_${index}`}>
                <ItemTemplate item={node.frontmatter} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
export const ItemTemplate = ({ item }) => {
  return (
    <Link to={item.url} className="productCard h-100 mx-2 pb-3">
      <div className="card h-100 shadow">
        <div className="card-img d-none d-lg-block">
          <GatsbyImage
            image={item.image.childImageSharp.gatsbyImageData}
            alt={item.name}
            className="card-img-top"
          />
        </div>
        <div className="card-header border-0 bg-transparent  text-center text-lg-start">
          <GatsbyImage
            image={item.logo.childImageSharp.gatsbyImageData}
            alt={item.name}
          />
        </div>
        <div className="card-body py-0 d-none d-lg-block">
          <ReactMarkdown children={item.description}></ReactMarkdown>
        </div>
        <div className="card-footer border-0 bg-transparent text-end pt-0 d-none d-lg-block">
          <span className="text-primary">Read More</span>
        </div>
      </div>
    </Link>
  )
}
ItemTemplate.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}
export default ProductsList
