import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import { Grid, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/grid"
import "swiper/css/pagination"
import "./styles/offerings.scss"

const IndustriesList = ({ industries_home }) => {
  const data = useStaticQuery(graphql`
    query IndustriesAllQuery {
      allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___sequence] }
        filter: { frontmatter: { templateKey: { eq: "industries-slug" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              templateKey
              name
              description
              url
              logo {
                childImageSharp {
                  gatsbyImageData(width: 120, quality: 100, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `)
  const industriesArr = data.allMarkdownRemark.edges

  return (
    <section
      className={`py-3 py-lg-5 IndustriesList ${
        typeof window !== "undefined" &&
        window.location.toString().includes("industries")
          ? "light"
          : "dark"
      }`}
    >
      <div className="container">
        <div className="row justify-content-md-center">
          {industries_home && (
            <div className="col-12 text-center mb-lg-4">
              <h2 className="section_heading">{industries_home.heading}</h2>

              <ReactMarkdown
                className="d-none d-lg-block"
                children={industries_home.description}
              ></ReactMarkdown>
            </div>
          )}

          <Swiper
            pagination={{
              clickable: true,
              bulletClass: `swiper-pagination-bullet bg-light`,
            }}
            breakpoints={{
              0: {
                slidesPerView: 2,
                grid: {
                  rows: 4,
                },
              },
              768: {
                slidesPerView: 3,
                grid: {
                  rows: 2,
                },
              },
              1024: {
                slidesPerView: 4,
                grid: {
                  rows: 2,
                },
              },
            }}
            modules={[Grid, Pagination]}
            className={`customSwiper ${
              industriesArr.length === 3 ? "center" : ""
            }`}
          >
            {industriesArr.map(({ node }, index) => (
              <SwiperSlide key={`Industries_${index}`}>
                <ItemTemplate item={node.frontmatter} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
export const ItemTemplate = ({ item }) => {
  return (
    <Link to={item.url} className="industriesCard h-100 mx-2 pt-3 pb-3">
      <div className="card text-light h-100  justify-content-center">
        <div className="card-header border-0 bg-transparent">
          <div className="card-img">
            <GatsbyImage
              image={item.logo.childImageSharp.gatsbyImageData}
              alt={item.name}
            />
          </div>
          <h5 className="card-title me-2 mb-0">{item.name}</h5>
        </div>
        <div className="card-body bg-transparent text-muted pt-0 d-none d-lg-block">
          {item.description}
        </div>
      </div>
    </Link>
  )
}
ItemTemplate.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

export default IndustriesList
