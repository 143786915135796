import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import HomeBannerComponent from "../components/home/HomeBannerComponent"
import MainPichComponent from "../components/home/MainPichComponent"
import ProductsList from "./products-list"
import IndustriesList from "./industries-list"
import ServicesList from "./services-list"
import ResourcesList from "./resources-list"
import ClientsList from "./clients-list"
import InterestedComponent from "../components/home/InterestedComponent"
import TestimonialsList from "./testimonials-list.jsx"
import JoinTeamComponent from "../components/home/JoinTeamComponent"
import { PageSeo } from "../components/PageSeo"

// eslint-disable-next-line

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <IndexPageTemplate frontmatter={frontmatter} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

const IndexPageTemplate = ({ frontmatter }) => {
  return (
    <div>
      <HomeBannerComponent banner_blurb={frontmatter.banner_blurb} />
      <MainPichComponent mainpitch_home={frontmatter.mainpitch_home} />
      <ProductsList products_home={frontmatter.products_home} />
      <IndustriesList industries_home={frontmatter.industries_home} />
      <ServicesList services_home={frontmatter.services_home} />
      <ResourcesList resources_home={frontmatter.resources_home} />
      <ClientsList />
      <InterestedComponent interested_home={frontmatter.interested_home} />
      <TestimonialsList />
      <JoinTeamComponent jointeam_home={frontmatter.jointeam_home} />
    </div>
  )
}

IndexPageTemplate.propTypes = {
  markdown: PropTypes.shape({
    mainpitch_home: PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
    }),
    banner_blurb: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      })
    ),
    products_home: PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
    }),
    industries_home: PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
    }),
    services_home: PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
    }),
    resources_home: PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
    }),
    interested_home: PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
      linkText: PropTypes.string,
    }),
    jointeam_home: PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      blurb: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string,
        })
      ),
    }),
  }),
}

export default IndexPage
export const Head = () => <PageSeo />

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
      frontmatter {
        banner_blurb {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FIXED)
            }
          }
        }
        mainpitch_home {
          heading
          description
          link
        }
        products_home {
          heading
          description
        }
        industries_home {
          heading
          description
        }
        services_home {
          heading
          description
        }
        resources_home {
          heading
          description
        }
        interested_home {
          heading
          description
          link
          linkText
        }
        jointeam_home {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(height: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          blurb {
            link
          }
        }
      }
    }
  }
`
